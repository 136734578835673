<template>
  <div class="main-company-profile">
    <div class="view-profile">
      <div class="view-left">
        <div class="header-profile">
          <div class="header-profile-image">
            <div class="image-container">
              <div class="image-company">
                <img :src="CompanyProfile.url_Logo" alt="" />
                <div class="image-button">
                  <div class="btn-img">
                    <i style="cursor: default" class="fas fa-camera"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="view-right-content">
          <div class="top">
            <h1>{{ CompanyProfile.name }}</h1>
          </div>
          <!-- <div class="bottom">
            <h3>6 Employee <span></span></h3>
          </div> -->
        </div>
      </div>
      <div class="view-right">
        <v-btn class="btn-edit-profile" @click="onUpdate">
          {{ $t("Profile.btn-edit") }}
        </v-btn>
      </div>
    </div>
    <div class="view-content">
      <div class="view-content-left">
        <h1>{{ $t("Profile.contact") }}</h1>
        <table class="table table-view">
          <tbody>
            <tr>
              <td>
                <div class="contact-profile">
                  <div class="icon-contact-info">
                    <i class="far fa-phone"></i>
                  </div>
                  <div class="title-contact">
                    <div class="main-title">
                      <p class="sm-text-title">
                        {{ $t("Profile.contact no") }}
                      </p>
                    </div>
                    <div class="sub-title">
                      {{ CompanyProfile.phone }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="contact-profile">
                  <div class="icon-contact-info">
                    <i class="fas fa-envelope"></i>
                  </div>
                  <div class="title-contact">
                    <div class="main-title">
                      <p class="sm-text-title">{{ $t("Profile.email") }}</p>
                    </div>
                    <div class="sub-title">
                      {{ CompanyProfile.email }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="contact-profile">
                  <div class="icon-contact-info">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  <div class="title-contact">
                    <div class="main-title">
                      <p class="sm-text-title">{{ $t("Profile.address") }}</p>
                    </div>
                    <div class="sub-title">
                      {{ CompanyProfile.address }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <h1>{{ $t("Profile.contact info") }}</h1>
        <table class="table table-view">
          <tbody>
            <tr>
              <td>
                <div class="contact-profile">
                  <div class="title-contact">
                    <div class="main-title">
                      <p class="sm-text-title">
                        {{ $t("Profile.business type") }}
                      </p>
                    </div>
                    <div class="sub-title">
                      {{ CompanyProfile.typeBusiness }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="contact-profile">
                  <div class="title-contact">
                    <div class="main-title">
                      <p class="sm-text-title">
                        {{ $t("Profile.founding date") }}
                      </p>
                    </div>
                    <div class="sub-title">
                      {{ CompanyProfile.founding_date }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <h1>{{ $t("Company.tax") }}</h1>

        <v-radio-group row v-model="CompanyProfile.include_salary_tax">
          <v-radio
            class="label-input"
            value="yes"
            :label="$t('Company.tax_yes')"
            readonly
          >
          </v-radio>
          <v-radio
            class="label-input"
            value="no"
            :label="$t('Company.tax_no')"
            readonly
          >
          </v-radio>
        </v-radio-group>

        <h1>{{ $t("Company.sso") }}</h1>

        <v-radio-group row v-model="CompanyProfile.sso_charge">
          <v-radio
            class="label-input"
            value="employee"
            :label="$t('Company.sso_emp')"
            readonly
          >
          </v-radio>
          <v-radio
            class="label-input"
            value="company"
            :label="$t('Company.sso_company')"
            readonly
          >
          </v-radio>
        </v-radio-group>
        <ModalEdit fullscreen title="Updated Profile">
          <template v-slot="{ close }">
            <update-profile
              :companyProfile="CompanyProfile"
              @close="close"
              @success="FetchCompanyProfile"
            />
          </template>
        </ModalEdit>
      </div>
    </div>
  </div>
</template>

<script>
import updateProfile from "./updateProfile";
export default {
  components: {
    updateProfile,
  },
  data() {
    return {
      CompanyProfile: {},
    };
  },
  methods: {
    FetchCompanyProfile() {
      this.$axios
        .get(`company/my-profile`)
        .then((res) => {
          this.CompanyProfile = res.data.data;
          console.log(CompanyProfile);
        })
        .catch(() => {});
    },
    onUpdate() {
      this.$store.commit("modalEdit_State", true);
    },
  },
  created() {
    this.FetchCompanyProfile();
  },
};
</script>

<style scoped lang="scss">
input[type="radio"] {
  appearance: none;
  border: 1px solid #d3d3d3;
  width: 30px;
  height: 30px;
  content: none;
  outline: none;
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

input[type="radio"]:checked {
  appearance: none;
  outline: none;
  padding: 0;
  content: none;
  border: none;
}

input[type="radio"]:checked::before {
  position: absolute;
  color: green !important;
  content: "\00A0\2713\00A0" !important;
  border: 1px solid #d3d3d3;
  font-weight: bolder;
  font-size: 21px;
}
.main-company-profile {
  width: 100%;
  height: 100vh;
  background: #ffffff;
  border-left: 2px solid #eeeeee;

  .view-profile {
    width: 100%;
    height: 150px;
    display: flex;
    padding-top: 60px;

    .view-left {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 80px;
      .view-right-content {
        height: 150px;
        width: 100%;
        position: absolute;
        left: 230px;

        .top {
          width: 100%;
          height: 80px;
          display: flex;
          align-items: flex-end;

          h1 {
            margin-left: 15px;
            font-size: 32px;
            color: $main-color-font;
            font-family: $font-family;
            text-transform: capitalize;
          }
        }

        .bottom {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: flex-start;
          h3 {
            margin-left: 15px;
          }
        }
      }
    }
    .view-right {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      position: relative;
      justify-content: flex-start;
      padding-left: 50px;

      .btn-edit-profile {
        background-color: $main-btn-color-remove !important;
        color: #ffffff !important;
        box-shadow: 0 2px 4px 1px rgb(0 0 0 / 12%);
        border: none;
        text-transform: capitalize;
        font-family: $font-family;
        font-weight: 400 !important;
        margin-top: 5px;
        width: 80px;
        transition: ease 0.2s;
      }
      .btn-edit-profile:hover {
        transform: translateY(-12%);
        box-shadow: 0 2px 4px 1px rgb(0 0 0 / 15%);
      }
    }
  }

  .view-content {
    width: 100%;
    min-height: 600px;
    display: flex;

    .view-content-left {
      width: 60%;
      padding: 10px 0 100px 80px;

      h1 {
        font-family: $font-family;
        font-size: 28px;
        text-transform: capitalize;
        font-weight: 500;
        color: $main-color-font;
      }
    }

    .view-content-right {
      width: 40%;
      padding: 10px 100px;

      h1 {
        font-family: $font-family;
        font-size: 28px;
        text-transform: capitalize;
        font-weight: 500;
        color: $main-color-font;
      }
    }
  }
}

.company-passcode {
  width: 100%;
  height: 100px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-weight: 600;
    font-size: 30px;
    text-transform: uppercase;
    font-family: $font-family;
    letter-spacing: 1px;
    color: $main-color-font;
  }
}
</style>
